.otpInputStyle {
	height: 28px !important;
	width: 100% !important;
	font-size: 8px;
	border: 1px solid #20327b;
	color: #20327b;
	border-radius: 3px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	background-color: unset !important;
}

@media (min-width: 300px) {
	.otpInputStyle {
		height: 32px !important;
		font-size: 10px;
	}
}

@media (min-width: 340px) {
	.otpInputStyle {
		height: 38px !important;
		font-size: 14px;
		border-radius: 6px;
	}
}

@media (min-width: 360px) {
	.otpInputStyle {
		height: 40px !important;
		font-size: 14px;
		border-radius: 6px;
	}
}

@media (min-width: 380px) {
	.otpInputStyle {
		height: 45px !important;
		font-size: 14px;
		border-radius: 6px;
	}
}

@media (min-width: 420px) {
	.otpInputStyle {
		height: 50px !important;
		font-size: 14px;
		border-radius: 6px;
	}
}

@media (min-width: 480px) {
	.otpInputStyle {
		height: 55px !important;
		width: 100% !important;
		font-size: 14px;
	}
}
