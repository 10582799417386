.sidebar::-webkit-scrollbar {
	display: none;
}

.sidebar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-top: 5rem;
}

.sidebar::-webkit-scrollbar {
	width: 0 !important;
}
