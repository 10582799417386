body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

pre {
	white-space: wrap !important;
	font: inherit !important;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.sidebar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
